import { Box, Link } from "@chakra-ui/react";
import NextLink from "next/link";
import type { Field, ImageField, LinkField } from "@sitecore-jss/sitecore-jss-nextjs";
import { useSitecoreContext } from "@sitecore-jss/sitecore-jss-nextjs";
import ImageCard from "commons/ui/ImageCard";
import { gtmCTAEvent } from "commons/head/GTMTracking";

interface Fields {
    Image: ImageField;
    Headline: Field<string>;
    Text?: Field<string>;
    CTA?: LinkField;
}

export type ImageCarouselElementProps = {
    fields: Fields;
    pageEditing?: boolean;
};

const ImageCarouselLinkElement = (props: ImageCarouselElementProps) => {
    return (
        <Link
            as={NextLink}
            href={`${props.fields?.CTA?.value.href}`}
            title={props.fields?.CTA?.value.title}
            position="relative"
            height="100%"
            display="flex"
            flexDir="column"
            data-group
            flex={props.pageEditing ? 1 : undefined}
            pb={props.fields?.CTA?.value.href && props.fields?.Text?.value.trim() ? "sp28" : 0}
            mb={
                props.fields?.CTA?.value.href && props.fields?.Text?.value.trim()
                    ? ["sp4", null, null, null, 0]
                    : 0
            }
            pointerEvents={props.pageEditing ? "none" : "all"}
            onClick={() => {
                gtmCTAEvent(
                    props.fields?.Headline.value,
                    "Image carousel link",
                    props.fields?.CTA?.value.href
                );
            }}
        >
            <ImageCard fields={props.fields} />
        </Link>
    );
};

const ImageCarouselElement = (props: ImageCarouselElementProps) => {
    const {
        sitecoreContext: { pageEditing },
    } = useSitecoreContext();
    if (!props.fields) return null;
    return (
        <>
            {props.fields?.CTA?.value.href && !pageEditing ? (
                <ImageCarouselLinkElement fields={props.fields} pageEditing={pageEditing} />
            ) : (
                <Box
                    lineHeight="none"
                    fontWeight="medium"
                    letterSpacing="wide"
                    position="relative"
                    height="100%"
                    display="flex"
                    flexDir="column"
                    flex={pageEditing ? 1 : undefined}
                    data-group
                    pb={
                        props.fields?.CTA?.value.href && props.fields?.Text?.value.trim()
                            ? "sp28"
                            : 0
                    }
                >
                    <ImageCard fields={props.fields} />
                </Box>
            )}
        </>
    );
};

export default ImageCarouselElement;

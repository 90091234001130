import Head from "next/head";

const publicUrl = process.env.VERCEL_DOMAIN_URL
    ? "https://" + process.env.VERCEL_DOMAIN_URL
    : process.env.PUBLIC_URL;

const Favicon = () => {
    return (
        <Head>
            <link rel="icon" href={`${publicUrl}/favicon.ico`} />
            <link
                rel="apple-touch-icon"
                sizes="180x180"
                href={`${publicUrl}/favicons/apple-touch-icon.png`}
            />
            <link
                rel="icon"
                type="image/png"
                sizes="32x32"
                href={`${publicUrl}/favicons/favicon-32x32.png`}
            />
            <link
                rel="icon"
                type="image/png"
                sizes="16x16"
                href={`${publicUrl}/favicons/favicon-16x16.png`}
            />
            <link rel="manifest" href={`${publicUrl}/favicons/manifest.json`} />
            <link
                rel="mask-icon"
                href={`${publicUrl}/favicons/safari-pinned-tab.svg`}
                color="#000000"
            />
            <meta name="msapplication-TileColor" content="#000000" />
            <meta name="theme-color" content="#ffffff"></meta>
        </Head>
    );
};

export default Favicon;

import type { Field } from "@sitecore-jss/sitecore-jss-nextjs";
import { useSitecoreContext } from "@sitecore-jss/sitecore-jss-nextjs";
import type { BoxProps } from "@chakra-ui/react";
import { Text, Flex, Box } from "@chakra-ui/react";
import colors, { gradients } from "themes/foundations/color";
import { mapGradient } from "themes/utils/mapGradient";
import type { FC } from "react";
import { useEffect, useState, useRef, useCallback } from "react";
import SplitType from "split-type";
import { MotionBox } from "utils/framer/motionbox";
import type { MotionValue } from "framer-motion";
import { useScroll, useTransform } from "framer-motion";
import { debounce } from "themes/utils/debounce";
import SCText from "commons/sc/SCText";

interface Fields {
    Quote: Field<string>;
    CaptionHeadline: Field<string>;
    CaptionText: Field<string>;
}

type QuoteLineProps = {
    index: number;
    lineText: string;
    linesCount: number;
    scrollYProgress: MotionValue<number>;
};

const QuoteLine: FC<QuoteLineProps> = ({ index, lineText, linesCount, scrollYProgress }) => {
    const start = ((100 / linesCount) * index) / 100;
    const end = ((100 / linesCount) * (index + 1)) / 100;

    const revealProgress = useTransform(scrollYProgress, [start, end], ["0%", "-100%"]);
    const [width, setWidth] = useState<string>("0%");

    const setProgressWidth = useCallback((progress: string) => {
        setWidth(progress);
    }, []);

    useEffect(() => {
        const revealProgressChange = revealProgress.onChange(setProgressWidth);

        return () => {
            revealProgressChange();
        };
    }, [revealProgress, setProgressWidth]);

    return (
        <Text overflow="hidden" size="quote" display="flex" justifyContent="center">
            <Box
                as="span"
                display="block"
                textAlign="center"
                bgGradient={mapGradient(gradients.purpleToOrange)}
                background={`linear-gradient(${gradients.quoteGradient.dir},${
                    colors.brand.lightGray
                } 50%, ${gradients.quoteGradient.colors.map((color) => {
                    return color;
                })})`}
                bgClip="text"
                flex={1}
                backgroundSize="200% 100%"
                backgroundPosition={`${width} 0`}
                transition="background-position 0.8s ease-in-out"
            >
                {lineText}
            </Box>
        </Text>
    );
};

interface QuoteCaptionProps {
    CaptionHeadline: Field<string>;
    CaptionText: Field<string>;
}

const QuoteCaption = (props: QuoteCaptionProps) => {
    return (
        <Flex
            as="footer"
            sx={{
                flexDir: "column",
                alignItems: "center",
                mt: ["sp48", null, null, "sp36"],
                flex: "0 0 auto",
                textAlign: "center",
            }}
        >
            <SCText textField={props.CaptionHeadline} size="bodySmall" fontWeight="bold" />
            <SCText textField={props.CaptionText} size="bodySmall" />
        </Flex>
    );
};

export type QuoteElementProps = BoxProps & {
    params: { [key: string]: string };
    fields: Fields;
    uid: string;
};

const QuoteScrollElement = (props: QuoteElementProps) => {
    const {
        sitecoreContext: { pageEditing },
    } = useSitecoreContext();

    const QuoteItemRef = useRef<HTMLDivElement>(null);
    const QuoteItemTextRef = useRef<HTMLParagraphElement>(null);

    const [splittedText, setSplittedText] = useState<HTMLElement[] | null>();
    const [isShown, setIsShown] = useState(false);
    const { scrollYProgress } = useScroll({
        target: QuoteItemRef,
        offset: [`start 70vh`, `end 70vh`],
    });

    useEffect(() => {
        if (!pageEditing && !props.params?.quoteLength) {
            const container = QuoteItemRef.current as HTMLElement;
            if (!QuoteItemTextRef.current) return;
            const text = new SplitType(QuoteItemTextRef.current, {
                types: "lines",
            });
            setSplittedText(text.lines);

            const resizeObserver = new ResizeObserver(
                debounce(() => {
                    text.split({});
                }, 500)
            );

            resizeObserver.observe(container);
            return () => {
                resizeObserver.disconnect();
                text.revert();
            };
        }
        return () => {};
    }, [pageEditing, props.params?.quoteLength]);

    return (
        <>
            <Text
                display="block"
                as={"blockquote"}
                ref={QuoteItemTextRef}
                textAlign="center"
                size="quote"
                bg="gray"
                bgClip="text"
                height={isShown && splittedText ? 1 : "auto"}
                pointerEvents="none"
                zIndex={-1}
                opacity={isShown && splittedText ? 0 : 1}
                position="relative"
                overflow="hidden"
            >
                {`"${props.fields?.Quote.value}"`}
            </Text>
            <MotionBox
                key={`Quote_item_${props.uid}`}
                display="flex"
                flexDir="column"
                alignItems="center"
                height="100%"
                ref={QuoteItemRef}
                viewport={{ once: true }}
                onViewportEnter={() => {
                    setIsShown(true);
                }}
            >
                {props.fields?.Quote && (
                    <>
                        {splittedText && (
                            <Box flex="0 0 auto">
                                {splittedText.map((line, index) => (
                                    <QuoteLine
                                        key={`Quote_item_${props.uid}_line_${line.textContent}`}
                                        index={index}
                                        linesCount={splittedText.length}
                                        lineText={line.innerText}
                                        scrollYProgress={scrollYProgress}
                                    />
                                ))}
                            </Box>
                        )}
                    </>
                )}
            </MotionBox>
            <QuoteCaption
                CaptionHeadline={props.fields?.CaptionHeadline}
                CaptionText={props.fields?.CaptionText}
            />
        </>
    );
};

const QuoteElement = (props: QuoteElementProps) => {
    const {
        sitecoreContext: { pageEditing },
    } = useSitecoreContext();

    const quoteStyles = pageEditing
        ? {
              color: "purple",
          }
        : {
              bgGradient: mapGradient(gradients.purpleToOrange),
              bgClip: "text",
          };

    if (!props.fields) return null;

    return (
        <Box flex={pageEditing ? 1 : undefined} textAlign="center">
            {(props.params?.quoteLength || pageEditing) && (
                <Flex
                    as={"blockquote"}
                    flexDir="column"
                    alignItems="center"
                    height="100%"
                    px="sp12"
                >
                    <SCText
                        textField={props.fields?.Quote}
                        textAlign="center"
                        size="quote"
                        flex={1}
                        _before={{
                            content: `'“'`,
                        }}
                        _after={{
                            content: `'”'`,
                        }}
                        {...quoteStyles}
                    />
                    <QuoteCaption
                        CaptionHeadline={props.fields?.CaptionHeadline}
                        CaptionText={props.fields?.CaptionText}
                    />
                </Flex>
            )}
            {!props.params?.quoteLength && !pageEditing && <QuoteScrollElement {...props} />}
        </Box>
    );
};
export default QuoteElement;

import color from "themes/foundations/color";
import { rgba } from "polished";
import type { ChakraStyledOptions } from "@chakra-ui/react";

const gradientArray = [
    color.brand.darkOrange,
    color.brand.red,
    color.brand.purple,
    color.brand.mediumBlue,
];

export const getRandomAnimatedGradient = (): ChakraStyledOptions => {
    // Get a cryptographically secure random number between 0 and 1
    const random = crypto.getRandomValues(new Uint32Array(1))[0] / (0xffffffff + 1);
    const randomIndex = Math.floor(random * gradientArray.length);
    const randomGradient = gradientArray[randomIndex];
    const randomRGBAGradient = rgba(randomGradient, 0.8);

    return {
        sx: {
            _before: {
                content: '""',
                position: "absolute",
                bottom: 0,
                left: ["auto", null, null, 0],
                right: [0, null, null, "auto"],
                transform: ["translate(100%, 100%)", null, null, "translate(-100%, 100%)"],
                borderRadius: "100%",
                background: `${randomRGBAGradient}`,
                boxShadow: `0px 0px 500px 600px ${randomRGBAGradient},inset 0px 0px 500px 250px ${randomRGBAGradient}`,
            },
        },
    };
};

import type { SystemStyleObject } from "@chakra-ui/react";
import {
    Box,
    AspectRatio,
    Flex,
    IconButton,
    RangeSlider,
    RangeSliderFilledTrack,
    RangeSliderThumb,
    RangeSliderTrack,
} from "@chakra-ui/react";
import type { CookieConsentFieldProps } from "./CookieConsent";
import CookieConsent from "./CookieConsent";
import Image from "next/image";
import useImageSizes, { imageSize } from "utils/hooks/useImageSizes";
import SCImage from "commons/sc/SCImage";
import { useCallback, useEffect, useRef, useState } from "react";
import { gtmCustomEvent } from "commons/head/GTMTracking";
import { useI18n } from "next-localization";
import { transparentize } from "polished";
import BundleIcon from "./BundleIcon";
import type { ImageField } from "@sitecore-jss/sitecore-jss-nextjs";
import { useSitecoreContext } from "@sitecore-jss/sitecore-jss-nextjs";

interface ThumbnailProps {
    thumbnailAlt?: string;
    thumbnailImage?: ImageField;
    thumbnailUrl?: string;
    ratio?: boolean;
    sx?: SystemStyleObject;
}

interface VideoPlayerProps {
    cookies?: CookieConsentFieldProps;
    videoUrl: string;
    thumbnail: ThumbnailProps;
}

const Thumbnail = (props: ThumbnailProps) => {
    const sizes = useImageSizes({ base: 1 });
    const thumbnailImgValue = props.thumbnailImage?.value;
    const noRatioImageSize = imageSize(
        thumbnailImgValue?.width as number,
        thumbnailImgValue?.width as number,
        thumbnailImgValue?.height as number
    );

    const imageSizeProps = props.ratio === false ? noRatioImageSize : imageSize(1120, 16, 9);
    return (
        <>
            {props.thumbnailUrl &&
            (!thumbnailImgValue?.src || thumbnailImgValue?.class === "scEmptyImage") ? (
                <Image
                    src={props.thumbnailUrl}
                    alt={props.thumbnailAlt ?? ""}
                    {...imageSize(1120, 16, 9)}
                    sizes={sizes}
                />
            ) : (
                <SCImage
                    imageField={props.thumbnailImage}
                    {...imageSizeProps}
                    sizes={sizes}
                    {...(props.ratio === false && {
                        sx: {
                            width: "100%",
                            height: "100%",
                        },
                    })}
                />
            )}
        </>
    );
};

const VideoPlayer = (props: VideoPlayerProps) => {
    const {
        sitecoreContext: { pageEditing },
    } = useSitecoreContext();

    const [sliderValue, setSliderValue] = useState<number>(0);
    const [isVideoShown, setIsVideoShown] = useState<boolean>(false);
    const videoRef = useRef<HTMLVideoElement>(null);
    const { t } = useI18n();

    const hasVideo = props.videoUrl !== "";

    const [lastLoggedPercentage, setLastLoggedPercentage] = useState<number>(0);

    const updateSliderDuration = () => {
        if (!videoRef.current || !isVideoShown) return;

        const progress = (videoRef.current.currentTime / videoRef.current.duration) * 100;
        setSliderValue(progress);

        // Determine the progress milestone and log if it's different from the last logged percentage
        let milestone: number | null = null;

        if (progress >= 25 && lastLoggedPercentage < 25) {
            milestone = 25;
        } else if (progress >= 50 && lastLoggedPercentage < 50) {
            milestone = 50;
        } else if (progress >= 75 && lastLoggedPercentage < 75) {
            milestone = 75;
        } else if (progress === 100 && lastLoggedPercentage < 100) {
            milestone = 100;
        }

        if (milestone !== null) {
            gtmCustomEvent("custom_event", {
                type: "video",
                sub_type: "video progress",
                value: `${milestone}%`,
                name: props.videoUrl,
            });
            setLastLoggedPercentage(milestone);
        }
    };

    const playPauseMedia = useCallback(() => {
        if (videoRef.current?.paused) {
            videoRef.current.play();

            gtmCustomEvent("custom_event", {
                type: "video",
                sub_type: "video play",
                name: props.videoUrl,
            });
        } else {
            videoRef.current?.pause();
        }
    }, [props.videoUrl]);

    const handleSliderChange = useCallback((value: number) => {
        if (videoRef.current) {
            const newProgress = (value / 100) * videoRef.current.duration;
            if (videoRef.current.currentTime && typeof newProgress === "number") {
                videoRef.current.currentTime = newProgress;
            } else {
                videoRef.current.currentTime = 0;
            }
        }
    }, []);

    const videoEnded = useCallback(() => {
        if (videoRef.current) {
            videoRef.current.pause();
            handleSliderChange(0);
            setIsVideoShown(false);
        }
    }, [handleSliderChange]);

    const closePlayer = useCallback(() => {
        if (videoRef.current) {
            videoRef.current.pause();
            videoRef.current.currentTime = 0;
            handleSliderChange(0);
            setIsVideoShown(false);
        }
    }, [handleSliderChange]); // Add an empty array as the second argument to useCallback

    const handleKeypress = useCallback(
        (e: KeyboardEvent) => {
            if (e.code === "Escape") {
                e.preventDefault();
                closePlayer();
            }

            if (e.code === "Space") {
                e.preventDefault();
                playPauseMedia();
            }
        },
        [closePlayer, playPauseMedia]
    );

    const onCanPlayThrough = useCallback(() => {
        if (isVideoShown && videoRef.current && !videoRef.current.paused) {
            videoRef.current?.play();
        } else {
            videoRef.current?.pause();
        }
    }, [isVideoShown]);

    useEffect(() => {
        if (!pageEditing) {
            document.addEventListener("keydown", handleKeypress);
        }

        return () => {
            document.removeEventListener("keydown", handleKeypress);
        };
    }, [handleKeypress, pageEditing]);

    useEffect(() => {
        if (!videoRef.current) return;
        if (isVideoShown) {
            videoRef.current.play();
        } else {
            videoEnded();
        }
    }, [isVideoShown, videoEnded]);

    // This displays an image instead of a video
    // It happens if the video is not present
    // IF THE COOKIES ARE NOT NEEDED WHEN DISPLAYING AN IMAGE CONSIDER MOVING THIS
    // INSIDE THE VIDEO MODULE
    if (!hasVideo) {
        return (
            <CookieConsent {...props.cookies}>
                <Box position="relative" data-group>
                    <Thumbnail ratio={false} {...props.thumbnail} />
                </Box>
            </CookieConsent>
        );
    }

    return (
        <CookieConsent {...props.cookies}>
            <Box position="relative" data-group>
                <AspectRatio
                    maxW="100%"
                    ratio={16 / 9}
                    position="relative"
                    borderRadius={4}
                    overflow="hidden"
                    sx={{
                        "video::-webkit-media-controls": {
                            display: ["flex", null, null, "none"],
                        },
                    }}
                >
                    <>
                        {!props.videoUrl.includes("?.") && (
                            <video
                                ref={videoRef}
                                src={props.videoUrl}
                                onCanPlayThrough={onCanPlayThrough}
                                onTimeUpdate={updateSliderDuration}
                                onEnded={videoEnded}
                            >
                                <track kind="captions" />
                            </video>
                        )}
                        {!isVideoShown && <Thumbnail ratio={false} {...props.thumbnail} />}
                    </>
                </AspectRatio>

                <Flex
                    position="absolute"
                    top={0}
                    bottom={0}
                    right={0}
                    left={0}
                    justifyContent="center"
                    alignItems="center"
                    {...(pageEditing && { pointerEvents: "none" })}
                    onClick={isVideoShown ? playPauseMedia : undefined}
                    sx={
                        isVideoShown
                            ? {
                                  opacity: [1, null, null, 0],
                                  cursor: "pointer",
                                  transition: "all 0.3s ease",

                                  _groupHover: {
                                      opacity: 1,
                                  },
                              }
                            : {
                                  opacity: 1,
                              }
                    }
                >
                    {isVideoShown && (
                        <>
                            <IconButton
                                aria-label={t("closeVideo")}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    closePlayer();
                                }}
                                variant="secondary"
                                p={0}
                                width={48}
                                height={48}
                                position="absolute"
                                top="sp8"
                                right="sp8"
                                icon={<BundleIcon name="Close" />}
                                pointerEvents="all"
                            />
                            <Flex
                                position="absolute"
                                bottom={8}
                                left={8}
                                right={8}
                                display={["none", null, null, "flex"]}
                            >
                                <IconButton
                                    aria-label={
                                        videoRef.current?.paused ? t("playVideo") : t("pauseVideo")
                                    }
                                    variant="secondary"
                                    p={0}
                                    width={48}
                                    height={48}
                                    borderTopRightRadius={0}
                                    borderBottomRightRadius={0}
                                    flex="0 0 auto"
                                    icon={
                                        videoRef.current?.paused ? (
                                            <BundleIcon name="PlayArrow" />
                                        ) : (
                                            <BundleIcon name="Pause" />
                                        )
                                    }
                                />
                                <RangeSlider
                                    defaultValue={[0, 100]}
                                    value={[sliderValue]}
                                    onChange={(value) => {
                                        handleSliderChange(value[0]);
                                    }}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                    }}
                                    position="relative"
                                    flex={1}
                                >
                                    <RangeSliderTrack
                                        height={48}
                                        bg={transparentize(0.5, "white")}
                                        borderTopRightRadius={4}
                                        borderBottomRightRadius={4}
                                        aria-label={t("seekVideo")}
                                    >
                                        <RangeSliderFilledTrack height={48} bg="black" />
                                    </RangeSliderTrack>
                                    <RangeSliderThumb index={0} />
                                </RangeSlider>
                            </Flex>
                        </>
                    )}
                    {!isVideoShown && (
                        <IconButton
                            aria-label={t("playVideo")}
                            onClick={() => {
                                setIsVideoShown(true);
                                gtmCustomEvent("custom_event", {
                                    type: "video",
                                    sub_type: "video play",
                                    name: props.videoUrl,
                                });
                            }}
                            variant="secondary"
                            p={0}
                            width={48}
                            height={48}
                            borderRadius="100%"
                            icon={<BundleIcon name="PlayArrow" />}
                            pointerEvents="all"
                        />
                    )}
                </Flex>
            </Box>
        </CookieConsent>
    );
};

export default VideoPlayer;
